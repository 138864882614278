import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { environment } from '../../../../../../environments/environment';
import { Account } from '../../../../../auth/models/account.model';
import { User } from '../../../../../auth/models/user.model';
import { LoginService } from '../../../../../auth/services/login.service';
import { Company } from '../../../../../models/company.model';
import { CompanyService } from '../../../../../services/company.service';
import { DataDogLoggerService } from '../../../../../services/data-dog-logger.service';
import { TrackRecordService } from '../../../../../services/trackrecord.service';
import { FilePreviewComponent } from '../../../../../ui/components/file-preview/file-preview.component';
import { Negotiation } from '../../models/negotiation.model';
import { Order } from '../../models/order.model';
import { NegotiationService } from '../../services/negotiation.service';

@Component({
  selector: 'negotiation-actions',
  exportAs: 'agNegotiationActions',
  templateUrl: './negotiation-actions.component.html',
  styleUrls: ['./negotiation-actions.component.css']
})
export class NegotiationActionsComponent implements OnInit, OnDestroy {

  @ViewChild('filePreviewer', { static: true }) private readonly filePreviewer: FilePreviewComponent;

  @Input() public company: Company;
  @Input() public negotiation: Negotiation;
  @Input() set order(o: Order) {
    this._order = o;
  };
  get order(): Order {
    return this._order || this.negotiation.order;
  }

  @Output() readonly saveReference = new EventEmitter();

  public disabledKeys: any = {};
  public editorAccount: boolean;
  public environment = environment;
  public isNegotiationOwner: boolean;
  public isOrderOwner: boolean;
  public isRepresented: boolean;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public reference_number: string = '';
  public user: User;

  private _order: Order;
  private account: Account;
  private subscriptions: Subscription[] = [];

  constructor(
    private companyService: CompanyService,
    private negotiationService: NegotiationService,
    private router: Router,
    private loginService: LoginService,
    private trackrecordService: TrackRecordService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  ngOnInit(): void {
    this.isRepresented = this.checkIfRepresented();

    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    }));

    this.subscriptions.push(this.companyService.watchAccount().subscribe(account => {
      if (account) {
        this.account = account;
        this.editorAccount = this.account.is.commercial || this.account.is.operations;
      }
    }));
  }

  public onSaveReference(): void {
    this.saveReference.emit();
  }

  public viewFile(file: string, title: string): void {
    this.processing = true;

    const observable = this.companyService.viewFile(this.company, this.negotiation, file);

    this.subscriptions.push(observable.subscribe({
      next: response => {
        if (response.url) {
          this.filePreviewer.preview(observable, response.name, undefined, response.url, title);
        }
        this.processing = false;
      },
      error: error => {
        this.disabledKeys[file] = true;
        this.processing = false;
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  public openMessenger(): void {
    this.router.navigate(['company', this.company.id, 'counter-order', this.negotiation.id], {
      queryParams: {
        messenger: 'open',
        r: Math.round(Math.random() * 9999) // Cache-buster
      }
    })
  }

  public goToProposeAmendments(): void {
    if (this.negotiation.status.id === 7) this.router.navigate(['company', this.company.id, 'counter-order', this.negotiation.id, 'propose-amendments']);
  }

  public cancelNegotiation(): void {
    let statusId = this.isOrderOwner ? 21 : 20;
    this.subscriptions.push(this.negotiationService.udpateStatus(this.negotiation, this.company.id, statusId).subscribe(negotiation => {
      this.negotiation = negotiation;
    }));
  }

  private checkIfRepresented(): boolean {
    this.isOrderOwner = this.order.company.id === this.company.id;
    this.isNegotiationOwner = this.negotiation.company ? this.negotiation.company.id === this.company.id : false;

    return (!this.isOrderOwner && !this.isNegotiationOwner);
  }

  /** Try to preview the track record PDF in the browser. */
  public preview(): void {
    this.filePreviewer.preview(this.trackrecordService.pdf(this.company.id, this.negotiation.entity, this.negotiation.id));
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
