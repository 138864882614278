import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { ErrorService } from '../../../../services/error.service';
import { Negotiation } from '../models/negotiation.model';
import { NegotiationService } from './negotiation.service';

@Injectable()
export class NegotiationResolver {

  constructor(
    private negotiationService: NegotiationService,
    private router: Router,
    private errorService: ErrorService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<{} | Negotiation> {

    const negotiationId = parseInt(route.paramMap.get('negotiationId'));
    const companyId = parseInt(route.parent.paramMap.get('companyId'));

    return this.negotiationService.get(companyId, negotiationId).pipe(
      take(1),
      map(negotiation => {
        if (negotiation) {
          if (!negotiation.id) {
            this.router.navigate(['/company/' + companyId + '/working-orders']);
            return;
          } else {
            return negotiation;
          }
        } else {
          return null;
        }
      }),
      catchError((error, caught) => {
        this.errorService.handleError(error);
        return EMPTY;
      })
    );
  }
}
