import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../../../models/company.model';
import { Negotiation } from '../../models/negotiation.model';
import { NegotiationService } from '../../services/negotiation.service';

/**
 * NegotiationLinker is a component intended to assist the [[User]] to link and/or
 * unlink [[Negotiation|Negotiations]].
 *
 * ## Usage
 * ``` html
 * <ag-negotiation-linker #negotiationLinker
 * [company]="company"></ag-negotiation-linker>
 * ```
 *
 * ### Related UI components:
 */
@Component({
  selector: 'ag-negotiation-linker',
  templateUrl: './negotiation-linker.component.html',
  styleUrls: ['./negotiation-linker.component.scss']
})
export class NegotiationLinkerComponent implements OnDestroy {

  @ViewChild('modalTemplate', { static: true }) public readonly modalTemplate: TemplateRef<any>;

  /**
   * [[Company]] that makes the link.
   */
  @Input() public company: Company;

  /** @ignore */
  public availableNegotiations: Negotiation[];
  /**
   * [[Negotiation]] to link.
   */
  public negotiation: Negotiation;
  public selectedNegotiation: Negotiation;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public show_details: boolean;

  private subscriptions: Subscription[] = [];
  private modalRef: BsModalRef;
  private modalSub: Subscription;

  constructor(
    private modalService: BsModalService,
    private negotiationService: NegotiationService
  ) { }

  /**
   * Opens the modal with the link wizard.
   */
  public link(negotiation: Negotiation): void {
    this.negotiation = negotiation;
    this.selectedNegotiation = undefined;
    this.getNegotiations();
    this.openModal(this.modalTemplate, 'modal-lg');
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.availableNegotiations = undefined;
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /**
   * Get Negotiations that meet all these conditions:
   * - Same Product
   * - Not linked to this Negotiation
   */
  private getNegotiations(): void {
    this.processing = true;

    this.subscriptions.push(this.negotiationService.getSuggested(this.company.id, this.negotiation.id).subscribe(negotiations => {
      this.availableNegotiations = negotiations;
      this.processing = false;
    }));
  }

  public linkSelected(negotiation: Negotiation): void {
    this.subscriptions.push(this.negotiationService.link(this.company.id, this.negotiation.id, [negotiation.id]).subscribe(negotiations => {
    }));

    this.closeModal();
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
