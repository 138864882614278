import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { PluckPipe } from 'ngx-pipes';
import { Subscription } from 'rxjs';

import { environment } from '../../../../../../environments/environment';
import { Company } from '../../../../../models/company.model';
import { GroupBy } from '../../../../../models/group-by.model';
import { Product } from '../../../../../models/product.model';
import { CompanyService } from '../../../../../services/company.service';
import { ComponentCommService } from '../../../../../services/component-comm.service';
import { IntercomService } from '../../../../../services/intercom.service';
import { ConfirmComponent } from '../../../../../ui/components/confirm/confirm.component';
import { FilterSetComponent } from '../../../../../ui/components/filter-set/filter-set.component';
import { LocationStringPipe } from '../../../../../ui/pipes/location-string.pipe';
import { setProp } from '../../../../../utilities/setProp';
import { TableFilters } from '../../../../../utilities/table-filters';
import { qualityToString } from '../../../../pipes/quality.pipe';
import { Order } from '../../models/order.model';
import { OrderService } from '../../services/order.service';

// declare const walkthrough: any;

@Component({
  selector: 'app-trading-orders',
  templateUrl: './trading-orders.component.html',
  styleUrls: ['./trading-orders.component.scss']
})
export class TradingOrdersComponent extends TableFilters implements OnInit, OnDestroy {

  @ViewChild('filterSet', { static: true }) public readonly selectedFilters: FilterSetComponent;
  @ViewChild('agreeConfirm', { static: true }) private readonly agreeConfirm: ConfirmComponent;

  public cancelButton: string = '';
  public company: Company;
  public companyId: number;
  public confirmBody: string = '';
  public confirmButton: string = '';
  public confirmTitle: string = '';
  /** The language currently used. */
  public currentLang: string;
  public environment = environment;
  public hasOrdersWithGeolocation: boolean = false;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public ordersByProduct: GroupBy<Product, Order>[];
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public selection: Order[] = [];
  public totalTons: number;
  public viewMode: string;// = this.viewModeEnabled ? 'gallery' : 'table';
  private pusherSubscription: Subscription;

  /** @ignore */
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private companyService: CompanyService,
    private orderService: OrderService,
    private intercomService: IntercomService,
    private componentComm: ComponentCommService,
    private translateService: TranslateService,
    private clipboardService: ClipboardService,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe,
    private locationStringPipe: LocationStringPipe
  ) {
    super(route, router);
    this.subscriptions.push(this.route.parent.params.subscribe(params => {
      this.companyId = parseInt(params['companyId']);
    }));
  }

  /** @ignore */
  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    this.componentComm.emit({ name: 'app-title', title: 'COMPANY.WORKING_ORDERS' });

    this.intercomService.track('working_orders-visited');
    // walkthrough('working_orders', false, this.translateService);

    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      // Default mode
      this.viewMode = company.market.configuration.order.allow_media ? 'gallery' : 'table';
      this.onFiltersChange = this.loadData;
      this.setupFilters();
    }));
  }

  private loadData(): void {
    this.loading = true;
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();

    this.pusherSubscription = this.orderService.watchWorkingOrders(this.company.id, this.filters).subscribe(ordersByProduct => {
      if (!ordersByProduct) return;

      this.parseOrdersResponse(ordersByProduct);
    });
  }

  private parseOrdersResponse(ordersByProduct: GroupBy<Product, Order>[]): void {
    this.ordersByProduct = ordersByProduct;

    // TODO: evitar campos calculados de esta manera, agregar getter o pipe
    this.ordersByProduct.forEach(group => {
      group.values.forEach(order => {
        setProp(order, this.translateService);
        order.quality_string = qualityToString(order.product_detail.quality, group.key);
        if (order.business_detail.delivery.geolocations?.length) {
          this.hasOrdersWithGeolocation = true;
        }
      });
    });

    this.sumTotals();
    this.loading = false;
  }

  private sumTotals(): void {
    this.totalTons = this.ordersByProduct
      .map(group => group.values)
      .reduce((orders1, orders2) => orders1.concat(orders2), [])
      .map(order => order.business_detail.quantity.value || 0)
      .reduce((v1, v2) => v1 + v2, 0);
  }

  public massiveBook(): void {
    let pluckPipe = new PluckPipe();
    let products = pluckPipe.transform(this.selection, 'product.id');

    const distinct = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    let distinctProducts = products.filter(distinct);

    if (distinctProducts.length > 1) {

      this.confirmBody = '<ul><li>El producto debe coincidir entre todas las órdenes.</li></ul>';
      this.confirmTitle = 'No se pueden anotar las ordenes seleccionadas';
      this.confirmButton = '';
      this.cancelButton = 'Entendido';

      this.subscriptions.push(this.agreeConfirm.askConfirmation().subscribe(conf => { }));

    } else {

      this.processing = true;

      this.subscriptions.push(this.orderService.massiveBook(this.selection, this.companyId).subscribe(res => {
        this.processing = false;
        this.router.navigate(['/company', this.companyId, 'my-operations'], {
          queryParams: {
            // status_group_orders: 1,
            order_by: '-updated_at'
          }
        });
      }));
    }
  }

  public gotoOrder(id): void {
    if (id) this.router.navigateByUrl('/company/' + this.companyId + '/order/' + id);
  }

  public clearSelection(): void {
    this.selection = Array();
  }

  // TODO: This should be a pipe
  public countryAndPort(order: Order): string {
    return order.product.attributes.country.name + ' / ' + order.business_detail.port.name;
  }

  public copy(): void {
    if (this.ordersByProduct.length) {
      const { configuration } = this.company.market;
      const parseDate = (date: Date): string => {
        return this.datePipe.transform(date, this.translateService.instant('GLOBAL.DATE_FORMAT.SM'));
      };
      let data: string[] = [];

      data.push(this.translateService.instant('COMPANY.WORKING_ORDERS'));

      this.ordersByProduct.forEach(group => {
        let data_group: string[] = [];
        data_group.push('*' + group.key.name + '*');

        group.values.forEach(order => {
          let data_order: string[] = [];
          // data_order.push(this.translateService.instant(order.operation_type === 'compra' ? 'GLOBAL.BID' : 'GLOBAL.SELL') + ':');
          if (order.quality_string) data_order.push(order.quality_string);

          if (configuration.location.enabled) data_order.push(this.locationStringPipe.transform(order.business_detail.delivery.locations));
          if (configuration.geolocation.enabled) data_order.push(this.locationStringPipe.transform(order.business_detail.delivery.geolocations));

          if (order.business_detail.price.type === 'flat') data_order.push(order.business_detail.price.unit.name + ' ' + this.decimalPipe.transform(order.business_detail.price.value));
          else if (order.business_detail.price.type === 'to_be_fixed') data_order.push(this.translateService.instant('PRICE_DISPLAY.TO_BE_FIXED_ABBR') + ' ' + order.business_detail.price.value);
          // data_order.push('(' + String.fromCodePoint(0x1F5D3) + ' ' + parseDate(order.business_detail.delivery.date_from) + ' - ' + parseDate(order.business_detail.delivery.date_to) + ')');
          data_order.push('(' + parseDate(order.business_detail.delivery.date_from) + ' - ' + parseDate(order.business_detail.delivery.date_to) + ')');

          data_group.push(data_order.join('\t'));
        });

        data.push(data_group.join('\n'));
      });

      this.clipboardService.copy(data.join('\n\n'));
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    super.ngOnDestroy();
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();
  }
}
