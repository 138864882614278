import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FutureOperation } from '../../../models/future-operation.model';
import { Price } from '../../../models/price.model';
import { Quantity } from '../../../models/quantity.model';
import { TableFilters } from '../../../utilities/table-filters';

/**
 * FuturesTable is a component that displays a table of
 * [[FutureOperation|futures or options operations]].
 * 
 * Filters can be predefined and specify which columns to hide.
 * 
 * ## Usage
 * ``` html
 * <futures-table
 * [filters]="{
 *   'filters[operator.name]': 'contains:agree'
 * }"
 * [company]="company"
 * [hideColumns]="['product', 'company']"
 * (load)="tableLoaded($event)"></futures-table>
 * ```
 * 
 * ### Related UI components:
 * - [[AddFutureModalComponent|future-modal]]
 */
@Component({
  selector: 'futures-table',
  templateUrl: './futures-table.component.html',
  styleUrls: ['./futures-table.component.scss']
})
export class FuturesTableComponent extends TableFilters implements OnInit, OnDestroy {

  /** List of columns of the table to hide. */
  @Input() public hideColumns: string[] = [];

  @Output('load') onLoad = new EventEmitter();

  /** @ignore */
  public operations: FutureOperation[];
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean;
  /** @ignore */
  public predefinedFilters: boolean;

  /** @ignore */
  constructor(
    /** @ignore */
    public route: ActivatedRoute,
    /** @ignore */
    public router: Router
  ) {
    super(route, router);
  }

  /** @ignore */
  ngOnInit(): void {
    const { configuration } = this.company.market;
    if (configuration.imported_data.collections &&
      configuration.imported_data.collections.includes('futures')) {
      if (this.filters) {
        this.predefinedFilters = true;
        this.loadData();
      } else {
        // Filters based on URL
        this.onFiltersChange = this.loadData;
        this.setupFilters();
      }
    }
  }

  private loadData(): void {
    this.loading = true;

    setTimeout(() => {
      const
        scope = this,
        sampleProd = [
          {
            "id": 1,
            "name": "Soja"
          },
          {
            "id": 2,
            "name": "Maíz"
          },
          {
            "id": 3,
            "name": "Trigo Pan"
          }
        ],
        sampleLoc = [
          { location: { name: 'Rosario' } },
          { location: { name: 'Buenos Aires' } },
          { location: { name: 'Fábrica Norte' } },
          undefined
        ],
        getRnd = function (arr: any[]) {
          return arr[Math.floor(Math.random() * arr.length)];
        },
        genOp = function (): FutureOperation {
          let no: FutureOperation = new FutureOperation(),
            pr = new Price(),
            er = new Price(),
            vl = new Quantity();

          pr.type = "flat";
          pr.unit = { id: 2, name: "USD", slug: "usd" };
          pr.value = (Math.random() * 100) + 90;

          er.type = "flat";
          er.unit = { id: 1, name: "$AR", slug: "$ar" };
          er.value = (Math.random() * 10) + 60;

          vl.type = { id: 1, name: "Fija", slug: "fixed" };
          vl.unit = { id: 1, name: "Tonelada métrica", name_plural: "Tonelada(s)", slug: "t", abbrev: "t" };
          vl.value = Math.ceil(Math.random() * 10) * 30;

          no.date = new Date();
          no.company = scope.company;
          no.operator = scope.company;
          no.position = new Date();
          no.type = { id: 1 };
          no.condition = { id: (Math.random() > .5) ? 1 : 2 };
          no.product = getRnd(sampleProd);
          no.delivery = getRnd(sampleLoc);
          no.price = pr;
          no.exchange_rate = er;
          no.volume = vl;
          no.observations = (Math.random() > .3) ? undefined : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.';

          return no;
        },
        genOps = function (n: number) {
          let oa: FutureOperation[] = [];
          for (let index = 0; index < n; index++) {
            oa.push(genOp());
          }

          return oa;
        }
      this.dataLoaded(genOps(2));
    }, 500);
  }

  private dataLoaded(data: FutureOperation[]): void {
    this.operations = data;
    this.loading = false;

    this.onLoad.emit({
      data: this.operations
    });
  }

  /** @ignore */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
