import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgPipesModule } from 'ngx-pipes';

import { ChatModule } from '../../../chat/chat.module';
import { UiModule } from '../../../ui/ui.module';
import { SharedModule } from '../shared/shared.module';
import { ClosedNegotiationsTableComponent } from './components/closed-negotiations-table/closed-negotiations-table.component';
import { CompanyTradedOrdersComponent } from './components/company-traded-orders/company-traded-orders.component';
import { CounterOrderComponent } from './components/counter-order/counter-order.component';
import { MyOperationsComponent } from './components/my-operations/my-operations.component';
import { NegotiationActionsComponent } from './components/negotiation-actions/negotiation-actions.component';
import { NegotiationDateComponent } from './components/negotiation-date/negotiation-date.component';
import { NegotiationDelegateComponent } from './components/negotiation-delegate/negotiation-delegate.component';
import { NegotiationLinkerComponent } from './components/negotiation-linker/negotiation-linker.component';
import { NegotiationListComponent } from './components/negotiation-list/negotiation-list.component';
import { NegotiationsDashboardComponent } from './components/negotiations-dashboard/negotiations-dashboard.component';
import { PreordersComponent } from './components/preorders/preorders.component';
import { ReferenceNumberPickerComponent } from './components/reference-number-picker/reference-number-picker.component';
import { TradedOrdersComponent } from './components/traded-orders/traded-orders.component';
import { TradingOrdersComponent } from './components/trading-orders/trading-orders.component';
import { ViewOrderComponent } from './components/view-order/view-order.component';
import { NegotiationService } from './services/negotiation.service';

@NgModule({
  declarations: [
    TradingOrdersComponent,
    TradedOrdersComponent,
    MyOperationsComponent,
    PreordersComponent,
    ViewOrderComponent,
    NegotiationListComponent,
    CounterOrderComponent,
    ClosedNegotiationsTableComponent,
    NegotiationActionsComponent,
    ReferenceNumberPickerComponent,
    NegotiationLinkerComponent,
    NegotiationDateComponent,
    NegotiationDelegateComponent,
    CompanyTradedOrdersComponent,
    NegotiationsDashboardComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiModule,
    FormsModule,
    NgPipesModule,
    SharedModule,
    RouterModule,
    ChatModule
  ],
  providers: [
    NegotiationService
  ]
})
export class CommercialModule { }
