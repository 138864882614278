<div class="loading" *ngIf="loading">
  <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
</div>

<ng-container *ngIf="!loading">
  <div class="horizontal-scroller">
    <table class="table table-condensed table-hover" sortable defaultSortBy="-date" #tableSortable="tableSortable">
      <thead>
        <tr class="tr-align-center">
          <th class="sap" sortBy="date" *ngIf="!hideColumns.includes('date')">
            {{ 'GLOBAL.DATE'|translate }}
            <!-- <column-filter
            *ngIf="!predefinedFilters"
            type="date"
            alignment="left"
            [value]="{
              condition: filters['date']?.condition,
              value: filters['date']?.value,
              field: 'date'
            }"
            (onChange)="changeFilter($event)"></column-filter> -->
          </th>
          <th class="sap hidden-xs" sortBy="condition.id" *ngIf="!hideColumns.includes('condition')">
            {{ 'FUTURES.CONDITION'|translate }}
            <!-- <column-filter
            *ngIf="!predefinedFilters"
            type="string"
            alignment="left"
            [value]="{
              condition: filters['type.name']?.condition,
              value: filters['type.name']?.value,
              field: 'type.name'
            }"
            (onChange)="changeFilter($event)"></column-filter> -->
          </th>
          <th class="sap" sortBy="type.id" *ngIf="!hideColumns.includes('type')">
            {{ 'GLOBAL.TYPE'|translate }}
            <!-- <column-filter
            *ngIf="!predefinedFilters"
            type="string"
            alignment="left"
            [value]="{
              condition: filters['type.name']?.condition,
              value: filters['type.name']?.value,
              field: 'type.name'
            }"
            (onChange)="changeFilter($event)"></column-filter> -->
          </th>
          <th sortBy="company.name" *ngIf="!hideColumns.includes('company')">
            {{ 'GLOBAL.COMPANY'|translate }}
            <!-- <column-filter
            *ngIf="!predefinedFilters"
            type="string"
            alignment="left"
            [value]="{
              condition: filters['company.name']?.condition,
              value: filters['company.name']?.value,
              field: 'company.name'
            }"
            (onChange)="changeFilter($event)"></column-filter> -->
          </th>
          <th sortBy="product.name" *ngIf="!hideColumns.includes('product')">
            {{ 'GLOBAL.PRODUCT'|translate }}
            <!-- <column-filter
            *ngIf="!predefinedFilters"
            type="string"
            alignment="left"
            [value]="{
              condition: filters['product.name']?.condition,
              value: filters['product.name']?.value,
              field: 'product.name'
            }"
            (onChange)="changeFilter($event)"></column-filter> -->
          </th>
          <th class="sap" *ngIf="!hideColumns.includes('observations')"></th>
          <th class="hidden-xs" *ngIf="!hideColumns.includes('delivery')">
            {{ 'GLOBAL.DELIVERY_TYPE_ABBR'|translate }}
            <!-- <column-filter
            *ngIf="!predefinedFilters"
            type="string"
            alignment="left"
            [value]="{
              condition: filters['product.name']?.condition,
              value: filters['product.name']?.value,
              field: 'product.name'
            }"
            (onChange)="changeFilter($event)"></column-filter> -->
          </th>
          <th class="text-center hidden-xs" sortBy="position" *ngIf="!hideColumns.includes('position')">
            {{ 'FUTURES.POSITION'|translate }}
            <!-- <column-filter
            *ngIf="!predefinedFilters"
            type="string"
            alignment="left"
            [value]="{
              condition: filters['product.name']?.condition,
              value: filters['product.name']?.value,
              field: 'product.name'
            }"
            (onChange)="changeFilter($event)"></column-filter> -->
          </th>
          <th class="sap" sortBy="volume.value" *ngIf="!hideColumns.includes('volume')">
            {{ 'FUTURES.VOLUME'|translate }}
            <!-- <column-filter
            *ngIf="!predefinedFilters"
            type="number"
            alignment="left"
            [value]="{
              condition: filters['volume.value']?.condition,
              value: filters['volume.value']?.value,
              field: 'volume.value'
            }"
            (onChange)="changeFilter($event)"></column-filter> -->
          </th>
          <th class="sap" sortBy="price.value" *ngIf="!hideColumns.includes('price')">
            {{ 'GLOBAL.PRICE'|translate }}
            <!-- <column-filter
            *ngIf="!predefinedFilters"
            type="number"
            alignment="left"
            [value]="{
              condition: filters['price.value']?.condition,
              value: filters['price.value']?.value,
              field: 'price.value'
            }"
            (onChange)="changeFilter($event)"></column-filter> -->
          </th>
          <th class="sap hidden-xs text-right" sortBy="exchange_rate.value" *ngIf="!hideColumns.includes('exchange_rate')">
            {{ 'FUTURES.EXCHANGE_RATE'|translate }}
            <!-- <column-filter
            *ngIf="!predefinedFilters"
            type="number"
            alignment="left"
            [value]="{
              condition: filters['exchange_rate.value']?.condition,
              value: filters['exchange_rate.value']?.value,
              field: 'exchange_rate.value'
            }"
            (onChange)="changeFilter($event)"></column-filter> -->
          </th>
          <th class="hidden-xs hidden-sm" sortBy="company.name" *ngIf="!hideColumns.includes('operator')">
            {{ 'FUTURES.OPERATOR'|translate }}
            <!-- <column-filter
            *ngIf="!predefinedFilters"
            type="string"
            alignment="left"
            [value]="{
              condition: filters['company.name']?.condition,
              value: filters['company.name']?.value,
              field: 'company.name'
            }"
            (onChange)="changeFilter($event)"></column-filter> -->
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="tr-align-center" *ngFor="let operation of operations|orderBy: tableSortable.sortBy;">
          <td class="sap text-uppercase micro text-muted" *ngIf="!hideColumns.includes('date')">
            <date-md [date]="operation.date"></date-md>
          </td>
          <td class="sap hidden-xs" *ngIf="!hideColumns.includes('condition')">
            <span *ngIf="operation.condition.id === 2" class="ag-label buy-bg">{{ 'GLOBAL.BID'|translate }}</span>
            <span *ngIf="operation.condition.id === 1" class="ag-label sell-bg">{{ 'GLOBAL.SELL'|translate }}</span>
          </td>
          <td class="sap small" *ngIf="!hideColumns.includes('type')">{{ 'FUTURES.TYPES.'+operation.type.id|translate }}</td>
          <td *ngIf="!hideColumns.includes('company')">
            <company-info [company]="operation.company" [activity]="false"></company-info>
            <div *ngIf="operation.operator && !hideColumns.includes('operator')" class="visible-xs visible-sm text-muted micro truncate">{{ 'FUTURES.OPERATOR'|translate }}: <b>{{ operation.operator.name }}</b></div>
          </td>
          <td *ngIf="!hideColumns.includes('product')">
            <b>{{ operation.product.name }}</b>
            <div *ngIf="!hideColumns.includes('delivery')" class="small text-muted visible-xs">{{ operation.delivery ? ([operation.delivery]|locationString) : ('FUTURES.NO_DELIVERY'|translate) }}</div>
            <div *ngIf="!hideColumns.includes('position')" class="visible-xs">{{ operation.position|date:'MM/yyyy' }}</div>
          </td>
          <td class="sap" *ngIf="!hideColumns.includes('observations')">
            <i tooltip="{{ operation.observations }}" placement="right" class="text-muted material-symbols-rounded"
            *ngIf="operation.observations">comment</i>
          </td>
          <td class="small text-muted hidden-xs" *ngIf="!hideColumns.includes('delivery')">{{ operation.delivery ? ([operation.delivery]|locationString) : ('FUTURES.NO_DELIVERY'|translate) }}</td>
          <td class="text-center hidden-xs" *ngIf="!hideColumns.includes('position')">{{ operation.position|date:'MM/yyyy' }}</td>
          <td class="sap text-right" *ngIf="!hideColumns.includes('volume')">
            <quantity-control [quantity]="operation.volume"></quantity-control>
          </td>
          <td class="sap" *ngIf="!hideColumns.includes('price')">
            <div class="price-box booked-bg">
              <div class="type visible-xs"
              [class.buy]="operation.condition.id === 1"
              [class.sell]="operation.condition.id === 2">
              {{ operation.condition.id === 1 ? ('GLOBAL.BID'|translate) : ('GLOBAL.SELL'|translate) }}</div>
              <div class="value">
                <price-display [price]="operation.price" [market]="company.market" [withoutYear]="true"></price-display>
              </div>
            </div>  
          </td>
          <td class="sap hidden-xs small text-right" *ngIf="!hideColumns.includes('exchange_rate')">
            <price-display [price]="operation.exchange_rate" [market]="company.market" [withoutYear]="true"></price-display>
          </td>
          <td class="hidden-xs hidden-sm" *ngIf="!hideColumns.includes('operator')"><company-info [company]="operation.operator" [activity]="false"></company-info></td>
        </tr>
      </tbody>
    </table>

    <!-- No data -->
    <div class="no-data" *ngIf="operations && operations.length === 0">
      <i class="material-symbols-rounded">not_interested</i>
      {{ 'FUTURES.NO_DATA'|translate }}
    </div>
  </div>
</ng-container>