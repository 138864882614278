import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../../../models/company.model';
import { CompanyService } from '../../../../../services/company.service';
import { Negotiation } from '../../models/negotiation.model';
import { NegotiationService } from '../../services/negotiation.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'ag-negotiation-delegate',
  templateUrl: './negotiation-delegate.component.html',
  styleUrls: ['./negotiation-delegate.component.scss']
})
export class NegotiationDelegateComponent implements OnDestroy {

  @ViewChild('modalTemplate', { static: true }) public readonly modalTemplate: TemplateRef<any>;

  /**
   * [[Company]] that makes the link.
   */
  @Input() public company: Company;

  public broker: Company;
  public brokers: Company[];
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  /**
   * [[Negotiation]] to delegate.
   */
  private negotiation: Negotiation;
  private subscriptions: Subscription[] = [];

  constructor(
    private orderService: OrderService,
    private negotiationService: NegotiationService,
    private modalService: BsModalService,
    private companyService: CompanyService
  ) { }

  /**
   * Opens the modal with the delegate wizard.
   */
  public delegate(negotiation: Negotiation): void {
    this.negotiation = negotiation;

    if (!this.brokers) this.subscriptions.push(this.companyService.getBrokers(this.company.id).subscribe(brokers => {
      this.brokers = brokers.sort((a, b) => a.name.localeCompare(b.name)); // Sorts alphabetically
    }));

    this.openModal(this.modalTemplate);
  }

  public confirm(): void {
    this.processing = true;
    if (this.negotiation.company.id === this.company.id) {
      // Delegate Negotiation
      this.subscriptions.push(this.negotiationService.broker(this.company.id, this.negotiation.id, this.broker).subscribe(response => {
        this.processing = false;
        this.closeModal();
      }));
    } else {
      // Delgate Order
      this.subscriptions.push(this.orderService.broker(this.company.id, this.negotiation.order.id, this.broker).subscribe(response => {
        this.processing = false;
        this.closeModal();
      }));
    }
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c, ignoreBackdropClick: true });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      // this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
