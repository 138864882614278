import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { plainToInstance } from 'class-transformer';
import { Subscription } from 'rxjs';

import { Currency } from '../../../../../models/currency.model';
import { Unit } from '../../../../../models/unit.model';
import { TableFilters } from '../../../../../utilities/table-filters';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'negotiations-dashboard',
  templateUrl: './negotiations-dashboard.component.html',
  styleUrls: ['./negotiations-dashboard.component.scss']
})
export class NegotiationsDashboardComponent extends TableFilters implements OnInit, OnDestroy, OnChanges {

  @Input() public enabled: boolean;

  public data: any;
  public colors: string[] = ['#264653', '#E9C46A', '#E76F51', '#2A9D8F', '#F4A261'];

  private pusherSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private orderService: OrderService
  ) {
    super(route, router);
    this.paginated = false;
  }

  ngOnInit(): void {
    // Filters based on URL
    this.onFiltersChange = this.loadData;
    this.setupFilters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.enabled) this.loadData();
  }

  private loadData(): void {
    if (this.enabled) {
      this.unsubscribePusher();

      this.pusherSubscription = this.orderService.getCompanyNegotiationsStats(this.company.id, this.filters).subscribe(response => {
        this.dataLoaded(plainToInstance(ItemData, response));
      });
    } else this.unsubscribePusher();
  }

  /** Receives loaded data */
  private dataLoaded(data: any): void {
    this.data = data;
  }

  private unsubscribePusher(): void {
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();
  }

  /** Evaluates if all the values of the array are zero. */
  public allZero(arr: []): boolean {
    return arr.every(val => val === 0);
  }

  /** @ignore */
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribePusher();
  }
}

class DataSummary {
  total: number; // Cantidad de Contratos
  volume: [ // Volumen total de los Contratos por unidad
    {
      unit: Unit,
      total: number, // Volumen en Contratos
      fixed: number, // Volumen de fijaciones
      applied: number, // Volumen aplicado a los Contratos
      invoiced: number, // Volumen facturado de los Contratos
      total_applied_without_invoice: number
    }
  ];
  invoiced: number; // Cantidad de Contratos con liquidación final
  balance: [ // Balance total de los Contratos por moneda
    {
      currency: Currency,
      value: number
    }?
  ]; // Balance total de los Contratos

  constructor() {
    this.total = 0;
    this.invoiced = 0;
  }
}

class ItemData { // Solo datos en el contexto de los filtros aplicados
  all: DataSummary;
  expired: DataSummary; // Contratos con fecha de entrega hasta < hoy
  to_expire: DataSummary; // Contratos con fecha de entrega hasta >= hoy y < hoy + 1 semana
  on_date: DataSummary; // Contratos con fecha de entrega hasta >= hoy + 1 semana
  buy: DataSummary; // Contratos como comprador
  sell: DataSummary; // Contratos como vendedor

  products: [ // Contratos por producto
    {
      id: number; // ID de producto
      name: string; // Nombre de producto
      all: DataSummary;
      buy: DataSummary; // Contratos como comprador
      sell: DataSummary; // Contratos como vendedor
    }?
  ];

  constructor() {
    this.all = new DataSummary();
    this.expired = new DataSummary();
    this.to_expire = new DataSummary();
    this.on_date = new DataSummary();
    this.buy = new DataSummary();
    this.sell = new DataSummary();
  }
}
