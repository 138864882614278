import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JSend } from '../../models/jsend.model';
import { Webhook } from '../../models/webhook.model';

@Injectable({
  providedIn: 'root'
})
export class WebhookService {

  private baseUrl = '/:apiBase/companies/:companyId/webhooks';
  private webhookByIdUrl: string = this.baseUrl + '/:webhookId';

  constructor(
    private http: HttpClient
  ) { }

  /** Returns all [[Company|Comapny's]] [[Webhook|Webhooks]]. */
  public get(companyId: number): Observable<Webhook[]> {
    const url = this.baseUrl
      .replace(":companyId", companyId.toString());

    return this.http.get<JSend<{
      webhooks: Webhook[];
    }>>(url).pipe(map(response => plainToInstance(Webhook, response.data.webhooks)));
  }

  /** Create a new [[Webhook]]. */
  public create(companyId: number, webhook: Webhook): Observable<Webhook> {
    const url = this.baseUrl
      .replace(":companyId", companyId.toString());

    return this.http.post<JSend<{
      webhook: Webhook;
    }>>(url, webhook).pipe(map(response => plainToInstance(Webhook, response.data.webhook)));
  }

  /** Updates a [[Webhook]]. */
  public update(companyId: number, webhook: Webhook): Observable<Webhook> {
    const url = this.webhookByIdUrl
      .replace(":companyId", companyId.toString())
      .replace(":webhookId", webhook.id.toString());

    return this.http.put<JSend<{
      webhook: Webhook;
    }>>(url, webhook).pipe(map(response => plainToInstance(Webhook, response.data.webhook)));
  }

  /** Deletes a [[Webhook]]. */
  public delete(companyId: number, webhook: Webhook): Observable<JSend<any>> {
    const url = this.webhookByIdUrl
      .replace(":companyId", companyId.toString())
      .replace(":webhookId", webhook.id.toString());

    return this.http.delete<JSend>(url);
  }
}
