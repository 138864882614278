import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Role } from '../../auth/models/role.model';
import { AddManager, ManagerRequest } from '../../models/manager.model';
import { TeamMember } from '../../models/teamMember.model';

@Injectable({
  providedIn: 'root'
})
export class ManagersService {

  private baseUrl: string = '/:apiBase/companies/:companyId';
  private managerRolesUrl: string = "/:apiBase/managerRoles";
  private managersUrl: string = this.baseUrl + '/team';
  private myRepresentativesUrl: string = this.managersUrl + '/my-representatives';
  private representativesUrl: string = this.managersUrl + '/representatives';
  private managersRequestsUrl: string = this.baseUrl + '/managers/requests';
  private acceptInvitationUrl: string = this.managersRequestsUrl + '/:invitationId/accept';
  // private managerByIdUrl: string = this.baseUrl + '/managers/:managerId';
  private addManagerUrl: string = this.baseUrl + '/managers';

  constructor(
    private http: HttpClient
  ) { }

  public getMyRepresentatives(companyId: number): Observable<TeamMember[]> {
    const url = this.myRepresentativesUrl
      .replace(":companyId", String(companyId));

    return this.http.get<TeamMember[]>(url).pipe(map(response => {
      return plainToInstance(TeamMember, response);
    }));
  }

  public getRepresentatives(companyId: number): Observable<TeamMember[]> {
    const url = this.representativesUrl
      .replace(":companyId", String(companyId));

    return this.http.get<TeamMember[]>(url).pipe(map(response => {
      return plainToInstance(TeamMember, response);
    }));
  }

  // public delete(companyId: number, managerId: number): Observable<any> {
  //   const url = this.managerByIdUrl
  //     .replace(":companyId", companyId.toString())
  //     .replace(":managerId", managerId.toString());

  //   return this.http.delete<any>(url);
  // }

  public acceptInvitation(companyId: number, invitationId: number): Observable<any> {
    const url = this.acceptInvitationUrl
      .replace(":companyId", String(companyId))
      .replace(":invitationId", String(invitationId))

    return this.http.post<any>(url, {})
  }

  public request(companyId: number, request: ManagerRequest): Observable<TeamMember> {
    const url = this.managersRequestsUrl.replace(':companyId', String(companyId))

    return this.http.post<TeamMember>(url, request).pipe(map(response => {
      return plainToInstance(TeamMember, response);
    }));
  }

  public addManager(companyId: number, manager: AddManager): Observable<TeamMember> {
    const url = this.addManagerUrl.replace(':companyId', String(companyId))

    return this.http.post<any>(url, manager);
  }

  public getRoles(): Observable<Role[]> {
    const url = this.managerRolesUrl

    return this.http.get<Role[]>(url);
  }
}
