import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, interval } from 'rxjs';
import { map } from 'rxjs/operators';

import { CurrentDateService } from '../../../services/current-date.service';

@Component({
  selector: 'ag-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.css']
})
export class CountdownComponent implements OnInit, OnDestroy {

  @Input('end') private endDate: Date;
  @Input() private expired: string = this.translateService.instant('COUNTDOWN.EXPIRED');

  @Output() readonly onZero = new EventEmitter();

  public message: string;

  private counter: Observable<number>;
  private subscriptions: Subscription[] = [];

  constructor(
    private currentDate: CurrentDateService,
    private translateService: TranslateService
  ) { }

  private dhms(t: number): string {
    if (t < 0) {
      this.onZero.emit();
      return this.expired;
    } else {
      let array = [];
      t += 1000; // adds a second to minimize zero display

      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(t / (1000 * 60 * 60 * 24)),
        hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60)),
        seconds = Math.floor((t % (1000 * 60)) / 1000);

      if (days > 0) {
        array = [
          '<span title="' + this.translateService.instant('COUNTDOWN.DAY') + '">' + days + this.translateService.instant('COUNTDOWN.DAY_ABBR') + '</span>',
          '<span title="' + this.translateService.instant('COUNTDOWN.HOUR') + '">' + hours + this.translateService.instant('COUNTDOWN.HOUR_ABBR') + '</span>'
        ];
      } else if (hours > 3) {
        array = [
          '<span title="' + this.translateService.instant('COUNTDOWN.HOUR') + '">' + hours + this.translateService.instant('COUNTDOWN.HOUR_ABBR') + '</span>',
          '<span title="' + this.translateService.instant('COUNTDOWN.MINUTE') + '">' + minutes + this.translateService.instant('COUNTDOWN.MINUTE_ABBR') + '</span>'
        ];
      } else {
        array = [
          '<span title="' + this.translateService.instant('COUNTDOWN.MINUTE') + '">' + (minutes + (hours * 60)) + this.translateService.instant('COUNTDOWN.MINUTE_ABBR') + '</span>',
          '<span title="' + this.translateService.instant('COUNTDOWN.SECOND') + '">' + seconds + this.translateService.instant('COUNTDOWN.SECOND_ABBR') + '</span>'
        ];
      }

      return '<b>' + array.join(' ') + '</b>';
    }
  }

  ngOnInit(): void {
    this.message = this.dhms(this.calcTimer());

    this.counter = interval(1000).pipe(map((x) => {
      return this.calcTimer();
    }));

    this.subscriptions.push(this.counter.subscribe(x => {
      this.message = this.dhms(x);
    }));
  }

  private calcTimer(): number {
    return this.endDate.getTime() - this.currentDate.get().getTime();
  }

  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
