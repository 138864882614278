import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../../../environments/environment';
import { Account } from '../../../../../auth/models/account.model';
import { Company } from '../../../../../models/company.model';
import { Product } from '../../../../../models/product.model';
import { CompanyService } from '../../../../../services/company.service';
import { TableFilters } from '../../../../../utilities/table-filters';
import { Negotiation } from '../../models/negotiation.model';
import { qualityToString } from '../../../../pipes/quality.pipe';

@Component({
  selector: 'closed-negotiations-table',
  templateUrl: './closed-negotiations-table.component.html',
  styleUrls: ['./closed-negotiations-table.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ClosedNegotiationsTableComponent),
    multi: true
  }]
})
export class ClosedNegotiationsTableComponent extends TableFilters implements OnInit, ControlValueAccessor, OnDestroy, AfterViewInit {

  @Input() public company: Company;
  @Input() public set negotiations(data: Negotiation[]) {
    if (data) this.parseNegotiationData(data);
  }
  get negotiations(): Negotiation[] {
    return this._negotiations;
  }
  /** Whether the selection checkboxes are shown or not. */
  @Input() public enableSelection: boolean;
  /** List of columns of the table to hide. */
  @Input() public hideColumns: string[] = [];
  /** If true hides the columns filters. */
  @Input() public hideFilters: boolean;
  @Input('product-header') public showProductHeader: boolean = true;
  @Input('show-unlink') public showUnlink: boolean;
  @Input('group-by-product') public groupByProduct: boolean;

  @Output() readonly onForceReload = new EventEmitter();
  @Output() readonly onViewInit = new EventEmitter();
  @Output() readonly unlink = new EventEmitter();

  public editorAccount: boolean;
  public atLeastOneExternalReference: boolean;
  /** The language currently used. */
  public currentLang: string;
  public negotiationsByProduct: Record<string, Negotiation[]>;
  public predefinedFilters: boolean;

  private account: Account;
  private _negotiations: Negotiation[];

  constructor(
    /** @ignore */
    public route: ActivatedRoute,
    /** @ignore */
    public router: Router,
    private companyService: CompanyService,
    private translateService: TranslateService
  ) {
    super(route, router);
  }

  /** @ignore */
  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    const { collections } = this.company.market.configuration.imported_data;
    const { modules } = environment;
    const notAvailable = (slug) => {
      return !collections.includes(slug) || !modules[slug];
    };

    if (notAvailable('contracts')) this.hideColumns.push('contract');
    if (notAvailable('invoices')) this.hideColumns.push('balance');
    if (notAvailable('unloads')) this.hideColumns.push('unloads');
    if (notAvailable('fixations')) this.hideColumns.push('fixations');
    if (!modules.slots) this.hideColumns.push('slots');

    this.subscriptions.push(this.companyService.watchAccount().subscribe(account => {
      if (account) {
        this.account = account;
        this.editorAccount = this.account.is.commercial || this.account.is.operations;
      }
    }));
  }

  ngAfterViewInit(): void {
    this.onViewInit.emit();
  }

  private parseNegotiationData(data: Negotiation[]): void {
    if (data) data.forEach(negotiation => {
      if (negotiation.data_external && negotiation.data_external.reference) this.atLeastOneExternalReference = true;
      if (negotiation.order) {
        this.setProp(negotiation, negotiation.order.product);
        negotiation.proposal.quality_string = qualityToString(negotiation.proposal.product_detail.quality, negotiation.order.product);
      } else console.log(negotiation)
      // TODO: Temporal, eliminar
      if (!negotiation.booking_date) {
        negotiation.booking_date = negotiation.updated_at;
      }
    });

    this.groupNegotiationsByProduct(data);
    this._negotiations = data;
  }

  /**
   * Groups a list of negotiations by their associated product.
   * 
   * The function organizes the `negotiationsByProduct` property, where each key represents
   * a unique product, and the value is an array of negotiations related to that product.
   * 
   * @param data - Array of negotiations to be grouped.
   */
  private groupNegotiationsByProduct(data: Negotiation[]): void {
    // Initialize the grouping object
    this.negotiationsByProduct = data.reduce((acc, negotiation) => {
      // Create a key based on the product ID
      const key = String(negotiation.order.product.id);

      // Initialize the array if the key does not exist
      if (!acc[key]) acc[key] = [];

      // Add the negotiation to the corresponding array
      acc[key].push(negotiation);

      return acc;
    }, {} as Record<string, Negotiation[]>); // Explicitly define the type for the accumulator
  }

  private setProp(negotiation: Negotiation, product: Product): void {
    if (!negotiation.props) negotiation.props = [];
    if (negotiation.proposal.business_detail.price.type === 'to_be_fixed') {
      // Price to be fixed
      negotiation.props.push({
        name: this.translateService.instant('GLOBAL.FIXING_CONDITIONS'),
        value: negotiation.proposal.business_detail.price.value,
        'class': null
      });
    }

    negotiation.props.push({
      name: this.translateService.instant('GLOBAL.QUALITY'),
      value: qualityToString(negotiation.proposal.product_detail.quality, product)
    });

    if (negotiation.proposal.destination && negotiation.proposal.destination.name) {
      negotiation.props.push({
        name: this.translateService.instant('ORDER.DEPOSIT_LOCATION'),
        value: negotiation.proposal.destination.name
      });
    }

    if (negotiation.proposal.commission) {
      negotiation.props.push({
        name: this.translateService.instant('GLOBAL.COMMISSION'),
        value: negotiation.proposal.commission + '%'
      });
    }

    if (negotiation.proposal.own_production) {
      negotiation.props.push({
        name: this.translateService.instant('ORDER.PRODUCTION'),
        value: this.translateService.instant('ORDER.OWN_PRODUCED')
      });
    }

    if (negotiation.proposal.general_observations) {
      negotiation.props.push({
        name: this.translateService.instant('GLOBAL.OBSERVATIONS'),
        value: negotiation.proposal.general_observations
      });
    }
  }

  // TODO: This should be a pipe
  public countryAndPort(negotiation: Negotiation): string {
    return negotiation.order.product.attributes.country.name + ' / ' + negotiation.proposal.business_detail.port.name;
  }

  public reload(): void {
    this.forceReload();
  }

  public forceReload(): void {
    this.onForceReload.emit();
  }

  // ngModel
  private _value: Negotiation[] = [];
  public get selected(): Negotiation[] { return this._value }
  public set selected(v: Negotiation[]) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.selected = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  /** @ignore */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
